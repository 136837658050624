import React from "react";
import { NavLink } from "react-router-dom";

export const NavBarBrand: React.FC = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/">
        <img
          className="nav-bar__logo"
          src="https://lze-innovation.de/wp-content/uploads/2022/04/lze-innovation_logo_2020-03-03_4c.png"
          alt="LZE logo"
          width="122"
          height="36"
        />
      </NavLink>
    </div>
  );
};
