import React from "react";
import { PageLayout } from "../components/page-layout";

// Stripe: If using TypeScript, add the following snippet to your file as well.
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}
 
export const SubscribePage: React.FC = () => {
 
  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Subscribe to Our Product
        </h1>
        <div className="content__body">
          <p id="page-description">
            <span>
              Are you interested in our services? Subscribe today and get access to the <strong>entire content</strong> of this website.
            </span>
          </p>
          <p>
          <stripe-pricing-table pricing-table-id="prctbl_1Pb0MBHlNG1RmEABQ4h3d5bX" publishable-key="pk_test_51KuidnHlNG1RmEABZvLaOT0QmnLhya4UMRgvO8MGDaO917IbWgCPcJee1ka4Myc4j90JlM5TNZk3T86m40AfnTWE00UjPFdA88" />
          </p>
        </div>
      </div>
    </PageLayout>
  );
};
