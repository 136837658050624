import React from "react";
import { NavLink } from "react-router-dom";

interface MobileNavBarBrandProps {
  handleClick: () => void;
}

export const MobileNavBarBrand: React.FC<MobileNavBarBrandProps> = ({
  handleClick,
}) => {
  return (
    <div onClick={handleClick} className="mobile-nav-bar__brand">
      <NavLink to="/">
        <img
          className="mobile-nav-bar__logo"
          src="https://lze-innovation.de/wp-content/uploads/2022/04/lze-innovation_logo_2020-03-03_4c.png"
          alt="LZE logo"
          width="82"
          height="24"
        />
      </NavLink>
    </div>
  );
};
