import React from "react";
import { Auth0Feature } from "./auth0-feature";

export const Auth0Features: React.FC = () => {
  const featuresList = [
    {
      title: "Internet of Things",
      description:
        "Die Entwicklung von smarten Sensoren und drahtlosen Technologien wie RFID, LPWAN oder Meshed Networks gehört zu den Kernkompetenzen unserer Forschungspartner. Wir liefern Ihnen die daraus entstehenden Prototypen und Produkte.",
      resourceUrl: "https://auth0.com/docs/connections",
      icon: "https://cdn.auth0.com/blog/hello-auth0/private-cloud-logo.svg",
    },
    {
      title: "Wireless Communication",
      description:
        "Short-, Medium- oder Long Range IoT-Technologien? Basierend auf Ihren anwendungsspezifischen Anforderungen wählen wir die geeignete Funktechnologie für Sie aus und unterstützen Sie bei der Implementierung.",
      resourceUrl: "https://auth0.com/docs/multifactor-authentication",
      icon: "https://cdn.auth0.com/blog/hello-auth0/private-cloud-logo.svg",
    },
    {
      title: "5G Broadband",
      description:
        "Wir ermöglichen Ihnen den Zugang zu 5G-Testbeds, um die neuesten Technologien in den Bereichen Broadband IoT, Critical IoT oder Massive IoT zu testen, und unterstützen Sie dabei, neue 5G-Technologien zu kommerzialisieren.",
      resourceUrl: "https://auth0.com/docs/attack-protection",
      icon: "https://cdn.auth0.com/blog/hello-auth0/private-cloud-logo.svg",
    },
    {
      title: "Artificial Intelligence",
      description:
        "Mit Hilfe von Machine- und Deep Learning-Verfahren analysieren unsere Forschungspartner Ihre Daten. Daraus erarbeiten wir konkrete Anwendungsfälle und unterstützen Sie dabei, die KI-Algorithmen in eine marktfähige Lösung zu überführen.",
      resourceUrl: "https://auth0.com/docs/actions",
      icon: "https://cdn.auth0.com/blog/hello-auth0/private-cloud-logo.svg",
    },
    {
      title: "Power Electronics",
      description:
        "Die Energieversorgung der Zukunft lässt sich ohne moderne leistungselektronische Komponenten und Systeme nicht realisieren. Wir unterstützen Sie dabei, die richtige Leistungselektronik für Ihre Anwendung auszuwählen.",
      resourceUrl: "https://auth0.com/docs/actions",
      icon: "https://cdn.auth0.com/blog/hello-auth0/private-cloud-logo.svg",
    },
    {
      title: "X-Ray",
      description:
        "Mit Hilfe von Machine- und Deep Learning-Verfahren analysieren unsere Forschungspartner Ihre Daten. Daraus erarbeiten wir konkrete Anwendungsfälle und unterstützen Sie dabei, die KI-Algorithmen in eine marktfähige Lösung zu überführen.",
      resourceUrl: "https://auth0.com/docs/actions",
      icon: "https://cdn.auth0.com/blog/hello-auth0/private-cloud-logo.svg",
    },
  ];

  return (
    <div className="auth0-features">
      <h2 className="auth0-features__title">Explore Auth0 Features</h2>
      <div className="auth0-features__grid">
        {featuresList.map((feature) => (
          <Auth0Feature
            key={feature.resourceUrl}
            title={feature.title}
            description={feature.description}
            resourceUrl={feature.resourceUrl}
            icon={feature.icon}
          />
        ))}
      </div>
    </div>
  );
};
